<template>
  <div>
    <dashboard-payments
      v-if="G_PROGRAMS_IN_NEW_PAYMENT.includes(programId)"
      :client-account-id="$route.params.idClient"
      :key="keyPaymentSchedule"
    ></dashboard-payments>
    <b-card v-else>
      <b-row>
        <b-col lg="5">
          <b-table
            ref="listCharges"
            small
            responsive
            :fields="fields1"
            :items="searchListCharges"
            show-empty
            sticky-header="50vh"
            :busy.sync="isBusy"
            class="font-small-3 text-justify blue-scrollbar"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template v-slot:cell(date)="data">
              <p class="mb-0 font-weight-bold">
                {{ data.item.created_at | myGlobal }}
              </p>
            </template>
            <template v-slot:cell(fee_or_charge)="data">
              <p class="mb-0 font-weight-bold">
                {{ data.item.fee }}
              </p>
            </template>
            <template v-slot:cell(amount)="data">
              <div class="d-flex align-items-center">
                <span style="width: 70px" class="mb-0 font-weight-bold">
                  $ {{ data.item.amount }}
                </span>
              </div>
            </template>
          </b-table>
          <b-row class="mb-1 mt-1">
            <b-col
              ><b-button
                variant="primary"
                :disabled="isResponsibleAccount"
                @click="openChargeModal(null)"
              >
                <feather-icon icon="PlusIcon" size="15" />
                ADD CHARGE
              </b-button></b-col
            >
          </b-row>
        </b-col>
        <b-col lg="7">
          <b-table
            ref="listPayments"
            small
            show-empty
            sticky-header="50vh"
            :busy.sync="isBusy"
            class="font-small-3 justify-content-start blue-scrollbar"
            :fields="fields2"
            :items="searchListPayments"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template v-slot:cell(date)="data">
              <div class="d-flex justify-content-start">
                <p style="width: 100px" class="mb-0 font-weight-bold mr-1">
                  {{ data.item.settlement_date | myGlobal }}
                </p>
                <!-- <feather-icon
                    v-if="
                    data.item.update_payment_date === 0 &&
                      data.item.edit === 1 &&
                      data.item.amount !== 0
                  "
                    size="18"
                    class="cursor-pointer"
                    icon="Edit3Icon"
                    @click="openUpdatePaymentModal(data.item.id, data.item.t_id)"
                /> -->
                <!-- <feather-icon
                    v-if="
                    data.item.update_payment_date === 1 &&
                      data.item.amount !== 0
                  "
                    v-b-tooltip.hover.bottom
                    icon="EyeIcon"
                    class="cursor-pointer"
                    size="18"
                    :title="
                    'Updated by: ' +
                      data.item.user_updater +
                      ' - Updated at: ' +
                      data.item.updated_at
                  "
                /> -->
                <feather-icon
                  v-if="
                    data.item.status_transaction_id != 1 &&
                    data.item.amount == 0 &&
                    data.item.type_transaction_id != 10 &&
                    data.item.type_transaction_id != 11
                  "
                  class="text-danger cursor-pointer"
                  size="18"
                  icon="XCircleIcon"
                  @click="deleteWithouPayment(data.item.t_id)"
                />
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <p
                class="mb-0 font-weight-bold mr-1"
                :style="
                  data.item.type_transaction_id == 14 &&
                  data.item.status_transaction_id != 1
                    ? 'color: red'
                    : ''
                "
              >
                {{ data.item.type }}
              </p>
            </template>
            <template v-slot:cell(transaction_id)="data">
              <p class="mb-0 font-weight-bold">
                {{ data.item.transaction_id }}
              </p>
            </template>

            <template v-slot:cell(amount)="data">
              <span
                v-if="
                  ![10, 11, 15].includes(data.item.type_transaction_id) ||
                  (data.item.type_transaction_id == 15 &&
                    data.item.method_transaction_id == null &&
                    data.item.modality_transaction_id == 7)
                "
                >$ {{ data.item.amount }}</span
              >
              <span
                v-if="
                  data.item.type_transaction_id === 10 ||
                  data.item.type_transaction_id === 11
                "
                >$ ({{ data.item.amount }})</span
              >
              <span
                v-if="
                  data.item.type_transaction_id === 15 &&
                  [null, 5].includes(data.item.modality_transaction_id)
                "
                style="color: red"
                >$ {{ "-" + data.item.amount }}</span
              >
              <span
                v-if="
                  data.item.type_transaction_id === 15 &&
                  data.item.method_transaction_id == null &&
                  data.item.modality_transaction_id == 6
                "
                style="color: green"
                >$ {{ "-" + data.item.amount }}</span
              >
            </template>
            <template v-slot:cell(method)="data">
              <div class="d-flex justify-content-start">
                <span
                  v-if="
                    data.item.type_transaction_id == 15 &&
                    data.item.method_transaction_id != 7
                  "
                  >-</span
                >
                <span
                  v-else
                  style="min-width: 100px"
                  class="mb-0 font-weight-bold"
                >
                  {{ data.item.method_payment }}
                </span>
                <!-- <feather-icon
                    v-if="
                    data.item.type_transaction_id != 10 &&
                      data.item.type_transaction_id != 11 &&
                      data.item.amount == 0 &&
                      roleId == 1
                  "
                    :id="`EyeIcon_1-${data.index}`"
                    v-b-tooltip.hover.bottom
                    class="text-primary cursor-pointer"
                    size="18"
                    icon="EyeIcon"
                    title="SHOW"
                    @click="openZeroPaymentTableModal(data.item.t_id)"
                /> -->

                <div>
                  <feather-icon
                    v-if="
                      data.item.type_transaction_id == 10 ||
                      data.item.type_transaction_id == 11
                    "
                    :id="`EyeIcon-${data.index}`"
                    v-b-tooltip.hover.bottom
                    icon="EyeIcon"
                    class="text-primary cursor-pointer"
                    size="18"
                    title="SHOW"
                    @click="openVoidRefundInfoModal(data.item.transaction_id)"
                  />
                </div>
                <tabler-icon
                  v-if="
                    data.item.type_transaction_id != 10 &&
                    data.item.type_transaction_id != 11 &&
                    data.item.void == 1 &&
                    data.item.w_card == 1 &&
                    roleId == 1 &&
                    data.item.amount != 0 &&
                    data.item.status_id != 4
                  "
                  v-b-tooltip.hover.bottom
                  size="18"
                  class="text-danger cursor-pointer"
                  icon="CoinIcon"
                  title="Void"
                  @click="openVoidRefundModal(data.item, 1)"
                />

                <feather-icon
                  v-if="
                    data.item.type_transaction_id != 10 &&
                    data.item.type_transaction_id != 11 &&
                    data.item.void == 0 &&
                    data.item.refund == 1 &&
                    data.item.w_card == 1 &&
                    roleId == 1 &&
                    data.item.amount != 0 &&
                    data.item.status_id != 4
                  "
                  v-b-tooltip.hover.bottom
                  icon="ClockIcon"
                  class="text-success cursor-pointer"
                  size="18"
                  title="Refund"
                  @click="openVoidRefundModal(data.item, 2)"
                />
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div :style="`color: ${data.item.color}`">
                <feather-icon
                  @click="ApproveOrDeclineHeldTransaction(data.item)"
                  v-if="data.item.status_id == 4 || data.item.status_id == 19"
                  class="cursor-pointer text-warning mr-1"
                  icon="RefreshCcwIcon"
                  size="15"
                />
                <span v-if="data.item.status_id == 19" class="text-warning">
                  Held for review</span
                >
                <span v-else> {{ data.item.status }}</span>
              </div>

              <!-- <div
                :style="`color: ${data.item.color}; cursor: ${
                  data.item.status_id != 4 ? `auto` : `pointer`
                }`"
                @click="
                  data.item.status_id == 4
                    ? ApproveOrDeclineHeldTransaction(data.item)
                    : null
                "
              >
                <feather-icon
                  v-if="data.item.status_id == 4"
                  icon="RefreshCcwIcon"
                  style="margin-right: 0.1rem"
                  size="15"
                />
                {{ data.item.status }}
              </div> -->
            </template>
          </b-table>
          <b-row class="mt-1">
            <b-col
              ><b-button
                variant="primary"
                class="d-flex align-items-center"
                :disabled="isResponsibleAccount"
                @click="openCreditCardInfoModal"
              >
                <feather-icon
                  icon="PlusIcon"
                  style="margin-right: 0.5rem"
                  size="15"
                />
                CREDIT CARD
                <span
                  v-if="countData > 0"
                  class="badge rounded-pill bg-danger small"
                  style="font-size: 90% !important; margin-left: 0.5rem"
                >
                  {{ countData }}</span
                >
              </b-button></b-col
            >
            <!-- <b-col><b-button
                v-if="roleId === 1 || roleId === 2"
                variant="primary"
                :disabled="isResponsibleAccount"
                @click="openZeroPaymentModal(null)"
            >
              <feather-icon
                  icon="PlusIcon"
                  size="15"
              />
              ZERO PAYMENT
            </b-button>
            </b-col> -->
            <b-col></b-col>
            <b-col
              ><b-button
                v-if="
                  (client.balance != '0.00' && Number(client.balance) <= 0) ||
                  modulId === 7 ||
                  modulId === 5
                "
                :disabled="
                  ((roleId == 3 && client.paid == 0) ||
                  (roleId != 3 && client.paid < 2)
                    ? false
                    : true) || isResponsibleAccountisResponsibleAccount
                "
                variant="primary"
                @click="openMonthlyPaymentModal"
              >
                <feather-icon icon="PlusIcon" size="15" />
                MONTHLY PAYMENT
              </b-button></b-col
            >
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col lg="4" class="mt-1"
          ><b-input-group>
            <template #prepend>
              <b-input-group-text class="title_lable"
                >TOTAL FEES AND CHARGES
              </b-input-group-text>
            </template>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <money
              v-if="finishedLoading"
              id="input-1"
              v-model="client.total_charge"
              v-bind="maskMoney"
              class="form-control w-auto Cmoney"
              disabled
              :style="{
                color: client.total_charge < 0 ? 'red' : null,
                opacity: '1',
              }"
            /> </b-input-group
        ></b-col>
        <b-col lg="4" class="mt-1"
          ><b-input-group>
            <template #prepend>
              <b-input-group-text class="title_lable">
                TOTAL PAYMENTS
              </b-input-group-text>
            </template>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />

            <money
              v-if="finishedLoading"
              id="input-2"
              v-model="client.total_payments"
              v-bind="maskMoney"
              class="form-control w-auto Cmoney"
              disabled
              :style="{
                color: client.total_payments < 0 ? 'red' : null,
                opacity: '1',
              }"
            /> </b-input-group
        ></b-col>
        <b-col lg="4" class="mt-1"
          ><b-input-group>
            <template #prepend>
              <b-input-group-text class="title_lable"
                >ACCOUNT BALANCE
              </b-input-group-text>
            </template>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />

            <money
              v-if="finishedLoading"
              id="input-3"
              v-model="client.balance"
              v-bind="maskMoney"
              class="form-control w-auto Cmoney"
              disabled
              :style="{
                color: client.balance < 0 ? 'red' : null,
                opacity: '1',
              }"
            /> </b-input-group
        ></b-col>
      </b-row>
    </b-card>
    <add-charge-modal
      v-if="addChargeOn"
      :title-add-charge="titleAddCharge"
      :edit="changeChargeId"
      @refresh="refresh"
      @close="closeChargeModal"
    />

    <credit-card-modal
      v-if="creditCardOn"
      :client="client"
      @close="closeCreditCardInfoModal"
    />

    <zero-payment-modal
      v-if="zeroPaymentOn"
      :zero-payment-transaction-id="zeroPaymentTransactionId"
      @refresh="refresh"
      @close="closeZeroPaymentModal"
    />

    <monthly-payment-modal
      v-if="monthlyPaymentOn"
      @refreshTable="refresh"
      @close="closeMonthlyPaymentModal"
    />

    <void-refund-modal
      v-if="voidRefundModalOn"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeVoidRefund"
      @refresh="refresh"
      @close="closeVoidRefundModal"
    />

    <void-refund-info
      v-if="voidrefundInfoModalOn"
      :transaction-id="transactionId"
      @close="closeVoidRefundInfoModal"
    />
    <payment-charge-modal
      v-if="paymentChargeOn"
      :data-charge="dataCharge"
      :title-add-charge="titleAddCharge"
      @close="closePaymentChargeModal"
    />
    <update-payment-modal
      v-if="updatePaymentModalOn"
      :transaction-id="transactionId"
      :client="client"
      @refresh="refresh"
      @close="closeUpdatePaymentModal"
    />
    <modal-approve-supervisor
      v-if="modalApproveSupervisorOn"
      :modal-approve-supervisor="modalApproveSupervisorOn"
      :from-bc-pay="true"
      @approveSup="updatePaymentModalOn = true"
      @closeModalApprove="modalApproveSupervisorOn = false"
    />
  </div>
</template>

<script>
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/fields1.data";
import dataFields2 from "@/views/commons/components/clients/dashboard/options/pay-module/fields2.data";

import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import CreditCardModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/CreditCardModal.vue";
import ZeroPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/ZeroPaymentModal.vue";
import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import VoidRefundModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue";
import VoidRefundInfo from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundInfoModal.vue";
import PaymentChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/PaymentChargeModal.vue";
import UpdatePaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/UpdatePaymentModal.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";
export default {
  components: {
    AddChargeModal,
    CreditCardModal,
    ZeroPaymentModal,
    MonthlyPaymentModal,
    VoidRefundModal,
    VoidRefundInfo,
    PaymentChargeModal,
    UpdatePaymentModal,
    ModalApproveSupervisor,
    DashboardPayments,
  },

  data() {
    return {
      updatePaymentModalOn: false,
      voidRefundModalOn: false,
      voidrefundInfoModalOn: false,
      modalApproveSupervisorOn: false,
      isBusy: false,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      addChargeOn: false,
      fields1: dataFields,
      fields2: dataFields2,
      listcharges: [],
      listpayments: [],
      finishedLoading: true,
      creditCardOn: false,
      zeroPaymentOn: false,
      monthlyPaymentOn: false,
      changeChargeId: null,
      titleAddCharge: "",
      transactionId: null,
      zeroPaymentTransactionId: null,
      dataCharge: null,
      paymentChargeOn: null,
      typeVoidRefund: null,
      voidRefundData: {},
      keyPaymentSchedule: Math.random(),
    };
  },

  watch: {
    async client(newVal) {
      if (newVal) {
        await Promise.all([
          this.$store.dispatch("DebtSolutionClients/A_COUNT_CREDIT_CARD", {
            id: this.client.lead_id,
          }),
        ]);
      }
    },
    RELOAD_TRANSACTIONS_STATUS() {
      this.refresh();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countData: "DebtSolutionClients/G_COUNT_CREDIT_CARD",
      RELOAD_TRANSACTIONS_STATUS: "TransactionStatusStore/G_REFRESH",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
    modulId() {
      return this.currentUser.modul_id;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("DebtSolutionClients/A_COUNT_CREDIT_CARD", {
        id: this.client.lead_id,
      }),
    ]);
  },

  methods: {
    refreshTable() {
      this.$refs.listCharges.refresh();
    },
    // openUpdatePaymentModal(id, t_id) {
    //   this.transactionId = id || t_id
    //   if (this.currentUser.role_id === 1 || this.currentUser.role_id === 2) {
    //     this.updatePaymentModalOn = true
    //   } else {
    //     this.modalApproveSupervisorOn = true
    //   }
    // },
    // closeUpdatePaymentModal() {
    //   this.updatePaymentModalOn = false
    // },

    openCreditCardInfoModal() {
      this.creditCardOn = true;
    },
    closeCreditCardInfoModal() {
      this.creditCardOn = false;
    },

    openVoidRefundInfoModal(transactionId) {
      this.transactionId = transactionId;
      this.voidrefundInfoModalOn = true;
    },
    closeVoidRefundInfoModal() {
      this.voidrefundInfoModalOn = false;
    },
    openVoidRefundModal(data, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        amount: data.amount,
        client_name: data.client_name,
        settlement_date: data.settlement_date,
      };
      this.voidRefundModalOn = true;
    },
    closeVoidRefundModal() {
      this.voidRefundModalOn = false;
    },

    // openZeroPaymentModal(id) {
    //   this.zeroPaymentTransactionId = id
    //   this.zeroPaymentOn = true
    // },

    // openZeroPaymentTableModal(id) {
    //   this.zeroPaymentTransactionId = id
    //   this.zeroPaymentOn = true
    // },
    // closeZeroPaymentModal() {
    //   this.zeroPaymentOn = false
    // },

    openMonthlyPaymentModal() {
      this.monthlyPaymentOn = true;
    },
    closeMonthlyPaymentModal() {
      this.monthlyPaymentOn = false;
    },

    openChargeModal(item) {
      this.changeChargeId = item;
      this.titleAddCharge = "ADD CHARGE";
      this.addChargeOn = true;
    },
    closeChargeModal() {
      this.addChargeOn = false;
    },

    async getCountExpirationCardTab() {
      try {
        const params = {
          id: this.client.lead_id,
        };
        const data = await ClientsOptionsServices.getCountExpirationCardTab(
          params
        );

        if (data.status == 200) {
          this.countData =
            data.data[0].countExpiration > 99
              ? "+99"
              : data.data[0].countExpiration;
        }
        return this.countData;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async searchListCharges() {
      try {
        const params = {
          id: this.$route.params.idClient,
        };
        const data = await ClientsOptionsServices.getListCharges(params);

        this.listcharges = data.data;
        this.listcharges.map((index) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (index.amount != null) {
            const data = formatter.format(index.amount);
            index.amount = data.replace("$", "");
          }
        });

        return this.listcharges;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async searchListPayments() {
      try {
        const params = {
          id: this.$route.params.idClient,
        };
        const data = await ClientsOptionsServices.getListPayments(params);

        this.listpayments = data.data;
        this.listpayments.map((index) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (index.amount != null) {
            const data = formatter.format(index.amount);
            index.amount = data.replace("$", "");
          }
        });

        return this.listpayments;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    paymentCharge(item) {
      const data = {
        id: item.id,
        fee: item.fee,
        amount: item.amount,
        t_charge: item.t_charge,
        t_id: item.t_id,
      };
      this.titleAddCharge = "ADD CHARGE";
      this.dataCharge = data;
      this.paymentChargeOn = true;
    },

    closePaymentChargeModal() {
      this.paymentChargeOn = false;
    },

    changeCharge(item) {
      this.titleAddCharge = "EDIT CHARGE";
      this.changeChargeId = item;
      this.addChargeOn = true;
    },

    async deleteWithouPayment(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.deleteWithoutPayment({
            id_transaction: id,
          });
          if (data.status === 200) {
            this.refresh();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async checkoutPayment(item) {
      if (item.status_id != 4) return;
      this.addPreloader();
      try {
        const params = {
          id: item.transaction_id,
          merchant: item.merchant,
          is_inital: 0,
        };
        const response = await ClientDashboardService.checkoutPayment(params);
        await this.$refs.listPayments.refresh();
        await Promise.all([
          this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
            id: this.$route.params.idClient,
          }),
        ]);
        if (response.data == 2) {
          this.$swal({
            title: "Oops...",
            text: "The transaction was declined",
            icon: "warning",
          });
        }
        this.removePreloader();
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },

    async ApproveOrDeclineHeldTransaction(transaction, isAutoApprove = false) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        if (!isAutoApprove) {
          this.refresh();
          this.showGenericToast({
            position: "top-right",
            variant: data.variant,
            icon: data.icon,
            title: data.message,
            text: data.text,
          });
        }
      } catch (error) {
        this.showErrorSwal(error.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
    async refresh() {
      this.$refs.listCharges.refresh();
      this.$refs.listPayments.refresh();
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
      ]);
    },
  },
};
</script>

<style scoped>
.title_lable {
  width: 200px;
}

@media (max-width: 1366px) {
  .Cmoney {
    max-width: 150px;
  }
}
</style>
